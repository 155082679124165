.link {
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}
.listIconStyle {
  max-width: 24px;
}

.toolbar {
  display: "flex";
  flex-direction: "row-reverse";
  justify-content: "flex-end";
}
.white {
  color: white;
}

.black {
  color: black;
}

.flexGrow {
  flex-grow: 1;
}

.theme {
  top: -10px;
}
