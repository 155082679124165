body {
  margin: 0;
  /* background-color: #3f51b5; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.__PrivateStripeElement {
  display: none;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #00ab55 rgb(121, 121, 126);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #c9c9c9;
}

*::-webkit-scrollbar-thumb {
  background-color: #00ab55;
  border-radius: 20px;
  border: 3px solid #00ab55;
}
