.app {
  margin-top: 68px;
}
video::-webkit-media-controls {
  display: none !important;
}
.appOtherBrowsers {
  margin: 50px;
  text-align: center;
}

.ahvbcshdvcshd {
  color: rgb(247, 247, 247);
  pointer-events: none;
}
